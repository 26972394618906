<template>
  <div class="overlay" v-if="proxy.stocker != null">
    <div class="move" :style="{ top: `${proxy.top}px`, left: `${proxy.left}px` }">
      <div class="listitem">
        <div class="listitem__item" v-if="!is_select_exculusion()" v-on:click.stop="hide">
          <div style="display: flex">
            <div style="margin-top: -2px">
              <svg xmlns="http://www.w3.org/2000/svg" height="14" width="14" viewBox="0 0 48 48">
                <path
                  d="M24 31.5q3.55 0 6.025-2.475Q32.5 26.55 32.5 23q0-3.55-2.475-6.025Q27.55 14.5 24 14.5q-3.55 0-6.025 2.475Q15.5 19.45 15.5 23q0 3.55 2.475 6.025Q20.45 31.5 24 31.5Zm0-2.9q-2.35 0-3.975-1.625T18.4 23q0-2.35 1.625-3.975T24 17.4q2.35 0 3.975 1.625T29.6 23q0 2.35-1.625 3.975T24 28.6Zm0 9.4q-7.3 0-13.2-4.15Q4.9 29.7 2 23q2.9-6.7 8.8-10.85Q16.7 8 24 8q7.3 0 13.2 4.15Q43.1 16.3 46 23q-2.9 6.7-8.8 10.85Q31.3 38 24 38Zm0-15Zm0 12q6.05 0 11.125-3.275T42.85 23q-2.65-5.45-7.725-8.725Q30.05 11 24 11t-11.125 3.275Q7.8 17.55 5.1 23q2.7 5.45 7.775 8.725Q17.95 35 24 35Z" />
              </svg>
            </div>
            <div style="margin-left: 4px">非表示にする</div>
          </div>
        </div>
        <div class="listitem__item" v-on:click.stop="edit">
          <div style="display: flex">
            <div style="margin-top: -2px">
              <svg xmlns="http://www.w3.org/2000/svg" height="14" width="14" viewBox="0 0 48 48">
                <path d="M6 36v-3h36v3Zm0-10.5v-3h36v3ZM6 15v-3h36v3Z" />
              </svg>
            </div>
            <div style="margin-left: 4px">表示項目編集</div>
          </div>
        </div>
      </div>
      <div class="allitems" v-if="proxy.isEditMode">
        <div class="allitems__item" style="position: relative" v-on:click.stop="allreset">
          <div style="position: absolute; top: 8px">
            <svg xmlns="http://www.w3.org/2000/svg" height="14" width="14" viewBox="0 0 48 48">
              <path
                d="M24 31.5q3.55 0 6.025-2.475Q32.5 26.55 32.5 23q0-3.55-2.475-6.025Q27.55 14.5 24 14.5q-3.55 0-6.025 2.475Q15.5 19.45 15.5 23q0 3.55 2.475 6.025Q20.45 31.5 24 31.5Zm0-2.9q-2.35 0-3.975-1.625T18.4 23q0-2.35 1.625-3.975T24 17.4q2.35 0 3.975 1.625T29.6 23q0 2.35-1.625 3.975T24 28.6Zm0 9.4q-7.3 0-13.2-4.15Q4.9 29.7 2 23q2.9-6.7 8.8-10.85Q16.7 8 24 8q7.3 0 13.2 4.15Q43.1 16.3 46 23q-2.9 6.7-8.8 10.85Q31.3 38 24 38Zm0-15Zm0 12q6.05 0 11.125-3.275T42.85 23q-2.65-5.45-7.725-8.725Q30.05 11 24 11t-11.125 3.275Q7.8 17.55 5.1 23q2.7 5.45 7.775 8.725Q17.95 35 24 35Z" />
            </svg>
          </div>

          All
        </div>
        <template v-for="(item, index) in this.proxy.columinfos" :key="index">
          <div v-if="!is_exclusion(item.name)" class="allitems__item" style="position: relative"
            v-bind:class="[!proxy.stocker.isHide(index) ? 'allitems__item--selected' : '', is_longname(item.name) ? 'allitems__item--longname' : '']"
            v-on:click.stop="toggle(item.index)">
            <div style="position: absolute; top: 8px; left: 6px;">
              <svg xmlns="http://www.w3.org/2000/svg" height="14" width="14" viewBox="0 0 48 48">
                <path
                  d="M24 31.5q3.55 0 6.025-2.475Q32.5 26.55 32.5 23q0-3.55-2.475-6.025Q27.55 14.5 24 14.5q-3.55 0-6.025 2.475Q15.5 19.45 15.5 23q0 3.55 2.475 6.025Q20.45 31.5 24 31.5Zm0-2.9q-2.35 0-3.975-1.625T18.4 23q0-2.35 1.625-3.975T24 17.4q2.35 0 3.975 1.625T29.6 23q0 2.35-1.625 3.975T24 28.6Zm0 9.4q-7.3 0-13.2-4.15Q4.9 29.7 2 23q2.9-6.7 8.8-10.85Q16.7 8 24 8q7.3 0 13.2 4.15Q43.1 16.3 46 23q-2.9 6.7-8.8 10.85Q31.3 38 24 38Zm0-15Zm0 12q6.05 0 11.125-3.275T42.85 23q-2.65-5.45-7.725-8.725Q30.05 11 24 11t-11.125 3.275Q7.8 17.55 5.1 23q2.7 5.45 7.775 8.725Q17.95 35 24 35Z" />
              </svg>
            </div>

            {{ item.name }}
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<style scoped>
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  /* pointer-events: none; */
  z-index: 1;
}

.move {
  position: absolute;
}

.listitem {
  padding: 8px;
  background: #fff;
  color: #000;
  z-index: 10;
  border-radius: 8px;
  font: normal normal normal 14px/9px Noto Sans JP;
  box-shadow: 0px 4px 10px #00000088;
  width: 150px;
  z-index: 2;
  color: #39434e;
}

.listitem__item {
  padding: 8px;
  cursor: pointer;
}

.listitem__item:hover {
  background: #ddd;
}

.allitems {
  margin-top: 16px;
  padding: 8px;
  background: #fff;
  color: #000;
  z-index: 10;
  border-radius: 8px;
  font: normal normal normal 13px/9px Noto Sans JP;
  box-shadow: 0px 4px 10px #00000088;
  width: 610px;
  position: absolute;
  /* height: 160px; */
  z-index: 2;
}

.allitems__item {
  padding: 8px;
  margin: 4px;
  width: 140px;
  text-align: center;
  float: left;
  border-radius: 8px;
  border: 0.5px solid #e3e3e3;
  color: #39434e;
  cursor: pointer;
  line-height: 100%;
}

.allitems__item--selected {
  background: #3b86ff;
  color: #fff;
}

.allitems__item:hover {
  background: #ddd;
}

.allitems__item--longname {
  padding-left: 22px;
}


</style>

<script>
export default {
  name: 'ColumnShowHide',
  data() {
    return {
      name: 'ColumnShowHide',
    };
  },
  computed: {},
  mounted() { },
  methods: {
    is_longname(title) {
      if (title.indexOf("_") > 0) {
        return true;
      }

      return false;
    },
    is_select_exculusion() {
      let name = '';
      try {
        name = this.proxy.columinfos[Number(this.proxy.select)].name;
      } catch (ex) {
        console.error(ex);
      }
      if (name == '種別') {
        return true;
      }
      if (name == '媒体') {
        return true;
      }
      if (name == 'カテゴリ') {
        return true;
      }
      if (name == 'イベント') {
        return true;
      }
      if (name == 'キャンペーン') {
        return true;
      }
      if (name == '広告グループ') {
        return true;
      }

      for (let i in this.proxy.exclusions) {
        let ex = this.proxy.exclusions[i];
        if (name == ex) {
          return true;
        }
      }

      return false;
    },
    is_exclusion(name) {
      if (name == '種別') {
        return true;
      }
      if (name == '媒体') {
        return true;
      }
      if (name == 'カテゴリ') {
        return true;
      }
      if (name == 'イベント') {
        return true;
      }
      if (name == 'キャンペーン') {
        return true;
      }
      if (name == '広告グループ') {
        return true;
      }

      for (let i in this.proxy.exclusions) {
        let ex = this.proxy.exclusions[i];
        if (name == ex) {
          return true;
        }
      }

      return false;
    },
    allreset() {
      this.proxy.stocker.reset();
    },
    toggle(index) {
      this.proxy.stocker.toggleNumberInvisible(index);
    },
    edit() {
      this.proxy.changeEditMode();
    },
    hide() {
      this.proxy.stocker.setNumberInvisible(this.proxy.select);
      this.proxy.clear();
    },
    cancel() {
      this.proxy.clear();
    },
  },
  components: {},
  props: {
    proxy: Object,
  },
};
</script>
